//********************************************************************
// App wide theme
export default class Th {

    //====================================================================
    // properties

    //- page -----
    static page: any = {
        md     : 'c',
        lt     : 'a,c',
        bkgClr : '#eeeeee',
        padT   : '20',
        padL   : '10',
        padR   : '10',
        padB   : '10',
    };

    //- content frame -----
    static frame: any = {
        ss         : '12|12|12|12|9',
        md         : 'c',
        lt         : 'a,c',
        txtDescr   : '20,B#ffffff;Helvetica', // d4ab50
        headBkgClr : '#005493',
        // bodyBor    : '2,#d4ab50',
        marB       : 20,
    };

    //- form -----
    // OB static formBkgDescr            = '#ffffff';
    // OB static formPaddingDescr        = '15,10,15,10';
    // OB static formMarginDescr         = '0,5,10,5';
    // OB static formBordersDescr        = '5,#8388ca;5';
    static form: any = {
        ss     : '12',
        md     : 'rw',
        lt     : 'a,a',
        bkgClr : '#ffffff',
        pad    : '15,10,15,10',
        mar    : 3,
        bor    : '1,#cccccc;2',
    };

    //- grid -----
    static grid = {
        ss    : '12',
        dir   : 'row',
        pad   : 10,
        hdBkg : '#cccccc',
        bor   : '1,#cccccc;2',
    };

    //- flow level 1 -----
    static flow1BkgDescr           = '#7e90be';
    static flow1PaddingDescr       = '5';
    static flow1MarginDescr        = '5';
    static flow1BordersDescr       = '5,#7e90be;5';
    static flow1ShadowDescr        = ''; // 1,1,#ff000000

    //- header -----
    static headerBkgDescr          = '#696dcf';

    //- main head -----
    static mainheadBkgDescr         = '/TC:BC,#ececf0,#5158b4,#858aca';
    static mainheadTextDescr        = '20,WCO#ffffff';
    static mainheadMarginDescr      = '0,5,0,5';
    static mainheadMarginDescrL     = '10,5,0,5';
    static mainheadMarginDescrU     = '0,5,10,5';
    static mainheadShadowDescr      = '1,1,#000000';
    static mainhead = {
        bkgClr: '#7678dd', // '|#ececf0,#5158b4,#858aca',
        txtDescr: '20,CO#ffffff',
        pad: '10',
        mar: '0,5,0,5',
    };

    //- subhead -----
    static subheadBkgDescr         = '#cccccc';
    static subheadTextDescr        = '20,WCO#ffffff';
    static subheadTextDescr2       = '18,WCO#ffffff';
    static subheadTextDescr3       = '16,WCO#ffffff';
    static subheadMarginDescr      = '0,5,0,5';
    static subhead = {
        bkgClr: '#cccccc',
        txtDescr: '20,CO#ffffff',
        pad: '5',
        mar: '0,5,0,5',
    };

    //- lists -----
    static list = {
        ss     : '12',
        md     : 'rw',
        lt     : 'c,a',
        bkgClr : '#8388ca',
        bor    : '1,#cccccc', // cccccc
    };

    //- listHead -----
    static listHead = {
        bkgClr: '#5b61c1', // 4e56b4
        txtDescr: '22,WC#ffffff',
        pad: '10,15,10,15',
        just: 'C,A'
    };

    //- listItemHead -----
    static listItemHead = {
        bkgClr: '#aec3d9',
        txtDescr: '22,WC#ffffff',
        pad: '10,5,10,5',
        just: 'C,A',
        icMarL: 5,
        icMarR: 5,
    };
    static listItemHeadBkg0 = '#aec3d9'; // bbbbbb
    static listItemHeadBkg1 = '#9fb4ca'; // aaaaaa

    //- list item -----
    static listItemLabel = {
        bkgClr: '#4e55b3',
        pad: '3,6,3,3',
        txtDescr: '16,L#ffffff',
        just: 'Z,A'
    };
    static listItemField = {
        bkgClr: '#ffffff',
        pad: '3',
        txtDescr: '16,L#000000',
    };

    //- head small -----
    static headSmall = {
        bkgClr: '#9fb4ca',
        bor: '3,#9fb4ca;3',
        txtDescr: '16,WC#ffffff',
        pad: '5,15,5,15',
        mar: '3,3,3,3',
        just: 'C,A',
    };

    //- field con & head -----
    static fieldCon = {
        dir: 'row',
        wrap: 1,
        mar: '5,5,5,5',
        pad: '2',
        bor: '1,#79747e;2',
    };
    static fieldHead = {
        txtDescr: '12,C#49454f', // '18,WC#ffffff',
        pad: '2,2,2,2',
        just: 'C,A',
    };

    //- field panel -----
    static fldpnlBkgDescr            = '#8388ca';
    static fldpnlPaddingDescr        = '10,5,10,5';
    static fldpnlMarginDescr         = '0,5,0,5';

    //- field -----
    static field: any = {
        ext         : '0|1|1|1|1',
        ss          : '12|0:7:1|0:3:1|0:3:1|0:3:1',
        labSs       : '0|4|2|2|2',
        bkgClr      : '#ffffff',
        pad         : '3,5,3,5',
        labMd       : 'r',
        labLt       : 'z,c',
        labTxtDescr : '16,#000000',
        labPad      : '16,5,0,5',
    };
    static field12: any = { // full width fields
        ext         : '0|1|1|1|1',
        ss          : '12|0:7:1|0:6:2|0:6:2|0:6:2',
        labSs       : '0|4|4|4|4',
        bkgClr      : '#ffffff',
        pad         : '3,5,3,5',
        labMd       : 'r',
        labLt       : 'z,c',
        labTxtDescr : '16,#000000',
        labPad      : '16,5,0,5',
    };    
    static fieldLg: any = {
        ext         : '0|1|1|1|1',
        ss          : '12|0:8|0:4|0:4|0:4',
        labSs       : '0|4|2|2|2',
        bkgClr      : '#ffffff',
        pad         : '3,5,3,5',
        labMd       : 'r',
        labLt       : 'z,c',
        labTxtDescr : '16,#000000',
        labPad      : '16,5,0,5',
        lines       : 5,
    };    
    static fieldLabel = {
        txtDescr: '18,#ffffffff',
    };

    //- field icon -----
    static fieldIcon = {
        dir : 'row',
        justM : 'C',
        pad : '17,0,12,0',
    };

    //- button -----
    static buttonClr               = '#283891';
    static buttonTxtClr            = '#ffffff';
    static buttonBkgDescr          = '#ffffff';
    static buttonLabelClr          = '#ffffff';
    static buttonLabelSize         = 24;
    static buttonLabelClr2         = '#ffffff';
    static buttonLabelSize2        = 14;
    static buttonLabelClr3         = '#ffffff';
    static buttonLabelSize3        = 20;
    static buttonPadding           = '10,5,10,5';
    static buttonMargin            = '10,5,10,5';
    static buttonMarginU           = '10,5,20,5';
    static buttonMarginL           = '20,5,10,5';
    static buttonMarginS           = '0,5,5,5';
    static buttonElev              = 3;
    static buttonBorder            = '';
    static button = {
        clr    : '#005493',
    };

    //- more buttons -----
    static submit: any = { 
        ss: '12|1:10:1|8:4|8:3:1|9:2:1',
        pad : "5,5,5,5",
        // md  : "c",
        clr : "#005493",
    };
    
    //- genText -----
    static genTextHeadTextDescr    = '20,WCO#273891';
    static genTextHeadTextDescr2   = '16,WJ#273891';

    //- menus -----
    static menuBtnBkgDescr         = '#ffffff';
    static menuBtnPaddingDescr     = '5,2,0,2';
    static menuBtnBordersDescr     = '5,#ffffff;5';
    static menuBtnShadowDescr      = '2,5,#808080';
    static menuBtnMarginDescr      = '5,10,5,10';
    static menuRow = {
        ss: '12',
        dir: 'row',
        wrap: 1,
        justM: 'C',
        align: 'A',
    };
    static menuButton = {
        ss: '4',
        elev: 4,
        pad: 5,
        mar: 5,
    };
    //- menu styles -----
    static menuFrame: any = {
        ss         : '12|12|6|6|4',
        md         : 'rw',
        lt         : 'a,a',
        txtDescr   : '20,B#ffffff;Helvetica',
        headBkgClr : '#005493',
        // bodyBor    : '2,#d4ab50',
        marB       : 20,
    };
    static menuBlock: any = {
        ss    : '12|12|12|12|12',
        pad   : '12',
        md    : 'rw',
        lt    : 'a,a',
    };
    static menuHeading: any = {
        ss       : '12',
        md       : 'r',
        lt       : 'c,c',
        bkgClr   : '#e32727',
        txtDescr : '18,O#ffffff',
        pad      : '10',
    };
    static menuBtn = {
        ss       : '12',
        pad      : '5,5,5,5',
        clr      : '#005493',
    };
    static menuBtnW = {
        ss       : '0|12|12',
        pad      : '5,5,5,5',
        clr      : '#005493',
    };
    static menuBtnM = {
        ss       : '12|0|0',
        pad      : '5,5,5,5',
        clr      : '#005493',
    };
    static menuBtnX = {
        ss       : '0|12|12',
        txtClr   : '#ffffff',
        pad      : '5,5,5,5',
        clr      : '#555555',
    };
    static btnMenuB = {
        ss       : '0|12|12',
        // txtDescr : '12,L#ffffff',
        // flex     : 1,
        pad      : '5,5,5,5',
        dir      : 'col',
        clr      : '#9B59B6',
    };



    //- logoSmall -----
    static logoSmallBsSpec         = '12,10:80,C';
    static logoSmallPaddingDescr   = '5,0,0,0';
    static logoSmallBordersDescr   = '5,#ffffff;5';

    //- misc -----
    static textLg: any = {
        txtDescr : '20,L#000000',
        flex     : 1,
        pad      : '5,5,5,5',
        md       : 'c',
    };

    //- report styles -----
    static reportBtn: any = {
        clr : '#005493',
        ss  : '2:8|3:6|3:6|3:6|3:6',
    };
    static reportBtn2: any = {
        clr : '#005493',
        ss  : '2:8|3:6|6:4|7:3|8:2',
    };    

    //- chart -----
    static lineChart = {
        ss     : '12',
        rat    : 1.5,
        elev   : 3,
        mar    : 3,
        labelXDescr: '12,#202020;Arial',
        labelYDescr: '12,#202020;Arial',
        titleXDescr: '14,O#283891;Arial',
        titleYDescr: '14,O#283891;Arial',
    };
    static lineColors = [
        '#0000AA', // vga blue
        '#00AA00', // vga green
        '#00AAAA', // vga cyan
        '#AA0000', // vga red
        '#AA00AA', // vga magenta
        '#AA5500', // vga brown
        '#AAAAAA', // vga light grey
        '#555555', // vga dark grey
        '#5555FF', // vga light blue
        '#55FF55', // vga light green
        '#55FFFF', // vga light cyan
        '#FF5555', // vga light red
        '#FF55FF', // vga light magenta
        '#FFFF55', // vga light yellow
        '#FFFFFF', // vga white
        '#000000', // vga black
    ];

    static chartBordersDescr       = '1,#cccccc;0';
    static chartTextDescr          = '16,BO#000000';
    static chartColor00            = '#000000';  // vga black
    static chartColor01            = '#0000AA';  // vga blue
    static chartColor02            = '#00AA00';  // vga green
    static chartColor03            = '#00AAAA';  // vga cyan
    static chartColor04            = '#AA0000';  // vga red
    static chartColor05            = '#AA00AA';  // vga magenta
    static chartColor06            = '#AA5500';  // vga brown
    static chartColor07            = '#AAAAAA';  // vga light grey
    static chartColor08            = '#555555';  // vga dark grey
    static chartColor09            = '#5555FF';  // vga light blue
    static chartColor10            = '#55FF55';  // vga light green
    static chartColor11            = '#55FFFF';  // vga light cyan
    static chartColor12            = '#FF5555';  // vga light red
    static chartColor13            = '#FF55FF';  // vga light magenta
    static chartColor14            = '#FFFF55';  // vga light yellow
    static chartColor15            = '#FFFFFF';  // vga white

    //====================================================================
    // static api
}
