import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// UserAuthRoleBase class : Generated : DO NOT MODIFY
export default class UserAuthRoleBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    userz_id: number|null;
    role_id: number|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.userz_id = 0;
        this.role_id = 0;
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.userz_id = obj.userz_id;
        this.role_id = obj.role_id;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            userz_id : this.userz_id,
            role_id : this.role_id,
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.userz_id = Ft.parseInt0Null( src.userz_id );
        this.role_id = Ft.parseInt0Null( src.role_id );
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.userz_id = null;
        this.role_id = null;
    }
}
