import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import Th from '../general/th';
import Ut from '../general/ut';

//********************************************************************
// PageMenuConfRegister
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageMenuConfRegister( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState<boolean>( false );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );

    const stl: any = { txtClr: '#ffffff' };

    return (<FtCanvas {...Th.page} lt='a,a'>
        {popup}

        <FtFrame text='Menu' {...Th.menuFrame}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Registrations List"                {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'AttendeeSearch' )} />
                <FtButton label="Attendee Statistics"               {...Th.menuBtn}   {...stl}  
                    show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK, Ut.RoleT.CONF_DISPLAY_STATS )} 
                    onTap={() => nav.push( 'ConfregisStatistics' )} 
                />
                <FtButton label="Quorum Detail Report"              {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'ReportConfregisQuorumDetail' )} />
                <FtButton label="Quorum Totals Report"              {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'ReportConfregisQuorumTotal' )} />
                <FtButton label="Summary Total Attendees Report"    {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'ReportConfregisSummaryTotal' )} />
                <FtButton label="Attendees List Report"             {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'ReportConfregisAttendees' )} />
                <FtButton label="Attendees by Region Report"        {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'ReportConfregisByRegion' )} />
                <FtButton label="Attendees by Type Report"          {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'ReportConfregisByType' )} />
                <FtButton label="Attendees by Region No ID Report"  {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'ReportConfregisByRegionNoId' )} />
                <FtButton label="Attendee Summary Report"           {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'ReportConfregisSummary' )} />
                <FtSpacer />
                <FtButton label="Back to Main Menu"                 {...Th.menuBtn}   {...stl}  onTap={nav.pop} />                
            </FtCon>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
}
