import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// ArcUploadBase class : Generated : DO NOT MODIFY
export default class ArcUploadBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    name: string|null;
    mime: string|null;
    file_size: number|null;
    upload_key: string|null;
    block_index: number|null;
    block_size: number|null;
    block_data: Uint8Array|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.name = '';
        this.mime = '';
        this.file_size = 0;
        this.upload_key = '';
        this.block_index = 0;
        this.block_size = 0;
        this.block_data = new Uint8Array(0);
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.name = obj.name;
        this.mime = obj.mime;
        this.file_size = obj.file_size;
        this.upload_key = obj.upload_key;
        this.block_index = obj.block_index;
        this.block_size = obj.block_size;
        this.block_data = FtRapi.decodeBin( obj.block_data );
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            name : FtRapi.encodeStr( this.name ),
            mime : FtRapi.encodeStr( this.mime ),
            file_size : this.file_size,
            upload_key : FtRapi.encodeStr( this.upload_key ),
            block_index : this.block_index,
            block_size : this.block_size,
            block_data : FtRapi.encodeBin( this.block_data ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.name = src.name;
        this.mime = src.mime;
        this.file_size = Ft.parseInt0Null( src.file_size );
        this.upload_key = src.upload_key;
        this.block_index = Ft.parseInt0Null( src.block_index );
        this.block_size = Ft.parseInt0Null( src.block_size );
        this.block_data = src.block_data;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.name = null;
        this.mime = null;
        this.file_size = null;
        this.upload_key = null;
        this.block_index = null;
        this.block_size = null;
        this.block_data = null;
    }
}
