import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// PastorFileBase class : Generated : DO NOT MODIFY
export default class PastorFileBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    pastor_id: number|null;
    arcrepo_id: number|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.pastor_id = 0;
        this.arcrepo_id = 0;
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.pastor_id = obj.pastor_id;
        this.arcrepo_id = obj.arcrepo_id;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            pastor_id : this.pastor_id,
            arcrepo_id : this.arcrepo_id,
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.pastor_id = Ft.parseInt0Null( src.pastor_id );
        this.arcrepo_id = Ft.parseInt0Null( src.arcrepo_id );
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.pastor_id = null;
        this.arcrepo_id = null;
    }
}
