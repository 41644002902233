import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// AssemblyRegionBase class : Generated : DO NOT MODIFY
export default class AssemblyRegionBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    oldid: number|null;
    region_name: string|null;
    region_no: string|null;
    status: string|null;
    is_filed: boolean|null;
    file_date: Date|null;
    move_no: string|null;
    nlf: boolean|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.oldid = 0;
        this.region_name = '';
        this.region_no = '';
        this.status = '';
        this.is_filed = false;
        this.file_date = moment( '0001-01-01' ).toDate();
        this.move_no = '';
        this.nlf = false;
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.oldid = obj.oldid;
        this.region_name = obj.region_name;
        this.region_no = obj.region_no;
        this.status = obj.status;
        this.is_filed = obj.is_filed;
        this.file_date = FtRapi.decodeDate( obj.file_date );
        this.move_no = obj.move_no;
        this.nlf = obj.nlf;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            oldid : this.oldid,
            region_name : FtRapi.encodeStr( this.region_name ),
            region_no : FtRapi.encodeStr( this.region_no ),
            status : FtRapi.encodeStr( this.status ),
            is_filed : FtRapi.encodeBool( this.is_filed ),
            file_date : FtRapi.encodeDate( this.file_date ),
            move_no : FtRapi.encodeStr( this.move_no ),
            nlf : FtRapi.encodeBool( this.nlf ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.oldid = Ft.parseInt0Null( src.oldid );
        this.region_name = src.region_name;
        this.region_no = src.region_no;
        this.status = src.status;
        this.is_filed = Ft.parseBool( src.is_filed );
        this.file_date = src.file_date;
        this.move_no = src.move_no;
        this.nlf = Ft.parseBool( src.nlf );
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.oldid = null;
        this.region_name = null;
        this.region_no = null;
        this.status = null;
        this.is_filed = false;
        this.file_date = moment( '0001-01-01' ).toDate();
        this.move_no = null;
        this.nlf = false;
    }
}
