import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup         from '../models/genLookup';
import Attendee          from '../models/attendee';
import Reception         from '../models/reception';

//********************************************************************
// PageReportConfrecepSummary
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageReportConfrecepSummary( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup         , setPopup         ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy          , setBusy          ] = useState( false );
    const [ mdlAttendee   , setMdlAttendee   ] = useState<Attendee|null>( new Attendee() );
    const [ mdlReception  , setMdlReception  ] = useState<Reception|null>( new Reception() );
    const [ lkpAssembly   , setLkpAssembly   ] = useState<GenLookup[]>( [] );
    const [ lkpRegion     , setLkpRegion     ] = useState<GenLookup[]>( [] );    

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( lkpAssembly, lkpRegion ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Report Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlAttendee, m2: mdlReception  }}
                onSubmit={onGenerate}
            >
                <FtFmTextField     name='m.surname'        label='Surname'        {...Th.field} />
                <FtFmTextField     name='m.rsaid_no'       label='ID Number'      {...Th.field} />
                <FtFmSearchField   name='m.assembly_id'    label='Assembly'       {...Th.field} data={lkpAssembly} />
                <FtFmSearchField   name='m.region_id'      label='Region'         {...Th.field} data={lkpRegion} />
                <FtFmDateField     name='m2.receive_date'  label='Reception Date' {...Th.field} fmt='YYYY/MM/DD' />
                <FtSpacer />
                <FtFmSubmit label='Generate Report' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void
    {
        //- load lookups -----
        setBusy( true );

        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setLkpRegion( data[1] );
                mdlReception!.receive_date = Ft.dtTodayAsDate();
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            complete: () => setBusy( false ),
        });
    }

    function onGenerate(): void
    {
        Ut.openReport( 'ReportConfrecepSummary', {
            surname      : mdlAttendee!.surname,
            rsaid_no     : mdlAttendee!.rsaid_no,
            assembly_id  : mdlAttendee!.assembly_id,
            region_id    : mdlAttendee!.region_id,
            receive_date : Ft.dtFmt( mdlReception!.receive_date ),
        } );
    }

}
