import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// ReceptionBase class : Generated : DO NOT MODIFY
export default class ReceptionBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    attendee_id: number|null;
    user_id: number|null;
    receive_date: Date|null;
    scan_dttm: Date|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.attendee_id = 0;
        this.user_id = 0;
        this.receive_date = moment( '0001-01-01' ).toDate();
        this.scan_dttm = moment( '0001-01-01 00:00:00' ).toDate();
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.attendee_id = obj.attendee_id;
        this.user_id = obj.user_id;
        this.receive_date = FtRapi.decodeDate( obj.receive_date );
        this.scan_dttm = FtRapi.decodeDttm( obj.scan_dttm );
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            attendee_id : this.attendee_id,
            user_id : this.user_id,
            receive_date : FtRapi.encodeDate( this.receive_date ),
            scan_dttm : FtRapi.encodeDttm( this.scan_dttm ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.attendee_id = Ft.parseInt0Null( src.attendee_id );
        this.user_id = Ft.parseInt0Null( src.user_id );
        this.receive_date = src.receive_date;
        this.scan_dttm = src.scan_dttm;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.attendee_id = null;
        this.user_id = null;
        this.receive_date = moment( '0001-01-01' ).toDate();
        this.scan_dttm = moment( '0001-01-01 00:00:00' ).toDate();
    }
}
