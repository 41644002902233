import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtText from '../first/ftText';
import Th from '../general/th';

//********************************************************************
// PageConfrecepStatistics
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageConfrecepStatistics( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup  , setPopup  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy   , setBusy   ] = useState( false );
    const [ counts , setCounts ] = useState( [ 0, 0, 0, 0, 0.0, 0 ] );  // attendee counts: [ att_in, att_tot, asm_in, asm_tot, asm_prc, is_quorum ]
    const duration = 30000; // 30000

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    const [ attIn, attTot, asmIn, asmTot, asmPrc, isQuorum ] = counts;

    const descrStl: string = '100,C#0e71b0';
    const statStl: string = '180,C#000000';
    const quorStl: string = !!isQuorum ? '200,#008000' : '200,#ff0000'

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Reception Statistics' {...Th.frame} >
            <FtText txtDescr={descrStl} text='Current Attendance' />
            <FtText txtDescr={statStl}  text={`${attIn}`} />
            <FtText txtDescr={descrStl} text='Assemblies Presence' />
            <FtText txtDescr={statStl}  text={`${asmIn} / ${asmTot} (${asmPrc.toFixed(1)}%)`} />
            <FtText txtDescr={quorStl}  text={`Quorum: ${!!isQuorum ? 'Yes' : 'No'}`} />
        </FtFrame>
    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        loadCounts();
        setTimeout( loadCounts, duration );
    }

    function dummyCounts(): void
    {
        const n = Math.floor( Math.random() * 1000 );
        setCounts([ n, 300 ]);
        setTimeout( dummyCounts, duration );
    }

    function loadCounts()
    {
        Ft.dirs( '~~ loadCounts ~~', Ft.dtmNow() ); ////
        FtRapi.callRead( 'confrecepStatsRead', 0, {
            success: ( result, data ) => {
                // attendee counts: [ att_in, att_tot, asm_in, asm_tot, asm_prc, is_quorum ]
                setCounts( [ data[0], data[1], data[2], data[3], data[4], data[5] ] );
            },
            error: ( result ) => {
                setCounts( [ 0, 0, 0, 0, 0.0, 0 ] );
            }
        });
        setTimeout( loadCounts, duration );
    }

}

