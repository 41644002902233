import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// ParameterBase class : Generated : DO NOT MODIFY
export default class ParameterBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    version_app: string|null;
    version_break: boolean|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.version_app = '';
        this.version_break = false;
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.version_app = obj.version_app;
        this.version_break = obj.version_break;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            version_app : FtRapi.encodeStr( this.version_app ),
            version_break : FtRapi.encodeBool( this.version_break ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.version_app = src.version_app;
        this.version_break = Ft.parseBool( src.version_break );
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.version_app = null;
        this.version_break = false;
    }
}
