import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// PastorBase class : Generated : DO NOT MODIFY
export default class PastorBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    pastid: number|null;
    member_no: string|null;
    first_name: string|null;
    surname: string|null;
    maiden_name: string|null;
    initials: string|null;
    personal_title: string|null;
    title_id: number|null;
    idno: string|null;
    gender_id: number|null;
    gender_name: string|null;
    birth_date: Date|null;
    birth_place: string|null;
    death_date: Date|null;
    instruments: string|null;
    spouse_member_no: string|null;
    spouse_first_name: string|null;
    spouse_surname: string|null;
    spouse_maiden_name: string|null;
    spouse_initials: string|null;
    spouse_personal_title: string|null;
    spouse_idno: string|null;
    spouse_birth_date: Date|null;
    spouse_birth_place: string|null;
    spouse_death_date: Date|null;
    spouse_talents: string|null;
    phone_home: string|null;
    fax_home: string|null;
    phone_work: string|null;
    postal_adr_1: string|null;
    postal_adr_2: string|null;
    postal_adr_city: string|null;
    postal_adr_code: string|null;
    postal_adr_country: string|null;
    assembly_id: number|null;
    assembly_name: string|null;
    assembly_no: string|null;
    region_id: number|null;
    region_no: string|null;
    pastor_status: string|null;
    children_count: number|null;
    marital_status_id: number|null;
    marital_status: string|null;
    wedding_date: Date|null;
    fulltime_date: Date|null;
    marriage_officiant_date: Date|null;
    q_number: string|null;
    ordained_date: Date|null;
    legitimize_date: Date|null;
    preachers_panel_tv_date: Date|null;
    preachers_panel_radio_date: Date|null;
    phone_extension: string|null;
    phone_request_line: boolean|null;
    remarks: string|null;
    language_preference: string|null;
    physical_adr_1: string|null;
    physical_adr_2: string|null;
    physical_adr_city: string|null;
    physical_adr_code: string|null;
    input_date: Date|null;
    position_id: number|null;
    position_no: string|null;
    celno: string|null;
    email: string|null;
    expiry_date: Date|null;
    photo: string|null;
    photoz_id: number|null;
    application_date: Date|null;
    notes: string|null;
    file_date: string|null;
    is_filed: boolean|null;
    move_no: string|null;
    last_updated: Date|null;
    add_change_date: Date|null;
    preferred_addressee: boolean|null;
    ge_comms: boolean|null;
    special_start_date: Date|null;
    special_end_date: Date|null;
    special_reason: string|null;
    file_no: string|null;
    bin: string|null;
    seconded_to: string|null;
    seconded_report_date: Date|null;
    called_date: Date|null;
    accepted_date: Date|null;
    seconded_date: Date|null;
    country: string|null;
    justice_case_active: boolean|null;
    last_edited: string|null;
    field1_text: string|null;
    field2_num: string|null;
    field3_date: Date|null;
    field_text2: string|null;
    field_text3: string|null;
    field_text4: string|null;
    field_text5: string|null;
    pastor_own: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.pastid = 0;
        this.member_no = '';
        this.first_name = '';
        this.surname = '';
        this.maiden_name = '';
        this.initials = '';
        this.personal_title = '';
        this.title_id = 0;
        this.idno = '';
        this.gender_id = 0;
        this.gender_name = '';
        this.birth_date = moment( '0001-01-01' ).toDate();
        this.birth_place = '';
        this.death_date = moment( '0001-01-01' ).toDate();
        this.instruments = '';
        this.spouse_member_no = '';
        this.spouse_first_name = '';
        this.spouse_surname = '';
        this.spouse_maiden_name = '';
        this.spouse_initials = '';
        this.spouse_personal_title = '';
        this.spouse_idno = '';
        this.spouse_birth_date = moment( '0001-01-01' ).toDate();
        this.spouse_birth_place = '';
        this.spouse_death_date = moment( '0001-01-01' ).toDate();
        this.spouse_talents = '';
        this.phone_home = '';
        this.fax_home = '';
        this.phone_work = '';
        this.postal_adr_1 = '';
        this.postal_adr_2 = '';
        this.postal_adr_city = '';
        this.postal_adr_code = '';
        this.postal_adr_country = '';
        this.assembly_id = 0;
        this.assembly_name = '';
        this.assembly_no = '';
        this.region_id = 0;
        this.region_no = '';
        this.pastor_status = '';
        this.children_count = 0;
        this.marital_status_id = 0;
        this.marital_status = '';
        this.wedding_date = moment( '0001-01-01' ).toDate();
        this.fulltime_date = moment( '0001-01-01' ).toDate();
        this.marriage_officiant_date = moment( '0001-01-01' ).toDate();
        this.q_number = '';
        this.ordained_date = moment( '0001-01-01' ).toDate();
        this.legitimize_date = moment( '0001-01-01' ).toDate();
        this.preachers_panel_tv_date = moment( '0001-01-01' ).toDate();
        this.preachers_panel_radio_date = moment( '0001-01-01' ).toDate();
        this.phone_extension = '';
        this.phone_request_line = false;
        this.remarks = '';
        this.language_preference = '';
        this.physical_adr_1 = '';
        this.physical_adr_2 = '';
        this.physical_adr_city = '';
        this.physical_adr_code = '';
        this.input_date = moment( '0001-01-01' ).toDate();
        this.position_id = 0;
        this.position_no = '';
        this.celno = '';
        this.email = '';
        this.expiry_date = moment( '0001-01-01' ).toDate();
        this.photo = '';
        this.photoz_id = 0;
        this.application_date = moment( '0001-01-01' ).toDate();
        this.notes = '';
        this.file_date = '';
        this.is_filed = false;
        this.move_no = '';
        this.last_updated = moment( '0001-01-01 00:00:00' ).toDate();
        this.add_change_date = moment( '0001-01-01 00:00:00' ).toDate();
        this.preferred_addressee = false;
        this.ge_comms = false;
        this.special_start_date = moment( '0001-01-01' ).toDate();
        this.special_end_date = moment( '0001-01-01' ).toDate();
        this.special_reason = '';
        this.file_no = '';
        this.bin = '';
        this.seconded_to = '';
        this.seconded_report_date = moment( '0001-01-01' ).toDate();
        this.called_date = moment( '0001-01-01' ).toDate();
        this.accepted_date = moment( '0001-01-01' ).toDate();
        this.seconded_date = moment( '0001-01-01' ).toDate();
        this.country = '';
        this.justice_case_active = false;
        this.last_edited = '';
        this.field1_text = '';
        this.field2_num = '';
        this.field3_date = moment( '0001-01-01' ).toDate();
        this.field_text2 = '';
        this.field_text3 = '';
        this.field_text4 = '';
        this.field_text5 = '';
        this.pastor_own = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.pastid = obj.pastid;
        this.member_no = obj.member_no;
        this.first_name = obj.first_name;
        this.surname = obj.surname;
        this.maiden_name = obj.maiden_name;
        this.initials = obj.initials;
        this.personal_title = obj.personal_title;
        this.title_id = obj.title_id;
        this.idno = obj.idno;
        this.gender_id = obj.gender_id;
        this.gender_name = obj.gender_name;
        this.birth_date = FtRapi.decodeDate( obj.birth_date );
        this.birth_place = obj.birth_place;
        this.death_date = FtRapi.decodeDate( obj.death_date );
        this.instruments = obj.instruments;
        this.spouse_member_no = obj.spouse_member_no;
        this.spouse_first_name = obj.spouse_first_name;
        this.spouse_surname = obj.spouse_surname;
        this.spouse_maiden_name = obj.spouse_maiden_name;
        this.spouse_initials = obj.spouse_initials;
        this.spouse_personal_title = obj.spouse_personal_title;
        this.spouse_idno = obj.spouse_idno;
        this.spouse_birth_date = FtRapi.decodeDate( obj.spouse_birth_date );
        this.spouse_birth_place = obj.spouse_birth_place;
        this.spouse_death_date = FtRapi.decodeDate( obj.spouse_death_date );
        this.spouse_talents = obj.spouse_talents;
        this.phone_home = obj.phone_home;
        this.fax_home = obj.fax_home;
        this.phone_work = obj.phone_work;
        this.postal_adr_1 = obj.postal_adr_1;
        this.postal_adr_2 = obj.postal_adr_2;
        this.postal_adr_city = obj.postal_adr_city;
        this.postal_adr_code = obj.postal_adr_code;
        this.postal_adr_country = obj.postal_adr_country;
        this.assembly_id = obj.assembly_id;
        this.assembly_name = obj.assembly_name;
        this.assembly_no = obj.assembly_no;
        this.region_id = obj.region_id;
        this.region_no = obj.region_no;
        this.pastor_status = obj.pastor_status;
        this.children_count = obj.children_count;
        this.marital_status_id = obj.marital_status_id;
        this.marital_status = obj.marital_status;
        this.wedding_date = FtRapi.decodeDate( obj.wedding_date );
        this.fulltime_date = FtRapi.decodeDate( obj.fulltime_date );
        this.marriage_officiant_date = FtRapi.decodeDate( obj.marriage_officiant_date );
        this.q_number = obj.q_number;
        this.ordained_date = FtRapi.decodeDate( obj.ordained_date );
        this.legitimize_date = FtRapi.decodeDate( obj.legitimize_date );
        this.preachers_panel_tv_date = FtRapi.decodeDate( obj.preachers_panel_tv_date );
        this.preachers_panel_radio_date = FtRapi.decodeDate( obj.preachers_panel_radio_date );
        this.phone_extension = obj.phone_extension;
        this.phone_request_line = obj.phone_request_line;
        this.remarks = obj.remarks;
        this.language_preference = obj.language_preference;
        this.physical_adr_1 = obj.physical_adr_1;
        this.physical_adr_2 = obj.physical_adr_2;
        this.physical_adr_city = obj.physical_adr_city;
        this.physical_adr_code = obj.physical_adr_code;
        this.input_date = FtRapi.decodeDate( obj.input_date );
        this.position_id = obj.position_id;
        this.position_no = obj.position_no;
        this.celno = obj.celno;
        this.email = obj.email;
        this.expiry_date = FtRapi.decodeDate( obj.expiry_date );
        this.photo = obj.photo;
        this.photoz_id = obj.photoz_id;
        this.application_date = FtRapi.decodeDate( obj.application_date );
        this.notes = obj.notes;
        this.file_date = obj.file_date;
        this.is_filed = obj.is_filed;
        this.move_no = obj.move_no;
        this.last_updated = FtRapi.decodeDttm( obj.last_updated );
        this.add_change_date = FtRapi.decodeDttm( obj.add_change_date );
        this.preferred_addressee = obj.preferred_addressee;
        this.ge_comms = obj.ge_comms;
        this.special_start_date = FtRapi.decodeDate( obj.special_start_date );
        this.special_end_date = FtRapi.decodeDate( obj.special_end_date );
        this.special_reason = obj.special_reason;
        this.file_no = obj.file_no;
        this.bin = obj.bin;
        this.seconded_to = obj.seconded_to;
        this.seconded_report_date = FtRapi.decodeDate( obj.seconded_report_date );
        this.called_date = FtRapi.decodeDate( obj.called_date );
        this.accepted_date = FtRapi.decodeDate( obj.accepted_date );
        this.seconded_date = FtRapi.decodeDate( obj.seconded_date );
        this.country = obj.country;
        this.justice_case_active = obj.justice_case_active;
        this.last_edited = obj.last_edited;
        this.field1_text = obj.field1_text;
        this.field2_num = obj.field2_num;
        this.field3_date = FtRapi.decodeDate( obj.field3_date );
        this.field_text2 = obj.field_text2;
        this.field_text3 = obj.field_text3;
        this.field_text4 = obj.field_text4;
        this.field_text5 = obj.field_text5;
        this.pastor_own = obj.pastor_own;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            pastid : this.pastid,
            member_no : FtRapi.encodeStr( this.member_no ),
            first_name : FtRapi.encodeStr( this.first_name ),
            surname : FtRapi.encodeStr( this.surname ),
            maiden_name : FtRapi.encodeStr( this.maiden_name ),
            initials : FtRapi.encodeStr( this.initials ),
            personal_title : FtRapi.encodeStr( this.personal_title ),
            title_id : this.title_id,
            idno : FtRapi.encodeStr( this.idno ),
            gender_id : this.gender_id,
            gender_name : FtRapi.encodeStr( this.gender_name ),
            birth_date : FtRapi.encodeDate( this.birth_date ),
            birth_place : FtRapi.encodeStr( this.birth_place ),
            death_date : FtRapi.encodeDate( this.death_date ),
            instruments : FtRapi.encodeStr( this.instruments ),
            spouse_member_no : FtRapi.encodeStr( this.spouse_member_no ),
            spouse_first_name : FtRapi.encodeStr( this.spouse_first_name ),
            spouse_surname : FtRapi.encodeStr( this.spouse_surname ),
            spouse_maiden_name : FtRapi.encodeStr( this.spouse_maiden_name ),
            spouse_initials : FtRapi.encodeStr( this.spouse_initials ),
            spouse_personal_title : FtRapi.encodeStr( this.spouse_personal_title ),
            spouse_idno : FtRapi.encodeStr( this.spouse_idno ),
            spouse_birth_date : FtRapi.encodeDate( this.spouse_birth_date ),
            spouse_birth_place : FtRapi.encodeStr( this.spouse_birth_place ),
            spouse_death_date : FtRapi.encodeDate( this.spouse_death_date ),
            spouse_talents : FtRapi.encodeStr( this.spouse_talents ),
            phone_home : FtRapi.encodeStr( this.phone_home ),
            fax_home : FtRapi.encodeStr( this.fax_home ),
            phone_work : FtRapi.encodeStr( this.phone_work ),
            postal_adr_1 : FtRapi.encodeStr( this.postal_adr_1 ),
            postal_adr_2 : FtRapi.encodeStr( this.postal_adr_2 ),
            postal_adr_city : FtRapi.encodeStr( this.postal_adr_city ),
            postal_adr_code : FtRapi.encodeStr( this.postal_adr_code ),
            postal_adr_country : FtRapi.encodeStr( this.postal_adr_country ),
            assembly_id : this.assembly_id,
            assembly_name : FtRapi.encodeStr( this.assembly_name ),
            assembly_no : FtRapi.encodeStr( this.assembly_no ),
            region_id : this.region_id,
            region_no : FtRapi.encodeStr( this.region_no ),
            pastor_status : FtRapi.encodeStr( this.pastor_status ),
            children_count : this.children_count,
            marital_status_id : this.marital_status_id,
            marital_status : FtRapi.encodeStr( this.marital_status ),
            wedding_date : FtRapi.encodeDate( this.wedding_date ),
            fulltime_date : FtRapi.encodeDate( this.fulltime_date ),
            marriage_officiant_date : FtRapi.encodeDate( this.marriage_officiant_date ),
            q_number : FtRapi.encodeStr( this.q_number ),
            ordained_date : FtRapi.encodeDate( this.ordained_date ),
            legitimize_date : FtRapi.encodeDate( this.legitimize_date ),
            preachers_panel_tv_date : FtRapi.encodeDate( this.preachers_panel_tv_date ),
            preachers_panel_radio_date : FtRapi.encodeDate( this.preachers_panel_radio_date ),
            phone_extension : FtRapi.encodeStr( this.phone_extension ),
            phone_request_line : FtRapi.encodeBool( this.phone_request_line ),
            remarks : FtRapi.encodeStr( this.remarks ),
            language_preference : FtRapi.encodeStr( this.language_preference ),
            physical_adr_1 : FtRapi.encodeStr( this.physical_adr_1 ),
            physical_adr_2 : FtRapi.encodeStr( this.physical_adr_2 ),
            physical_adr_city : FtRapi.encodeStr( this.physical_adr_city ),
            physical_adr_code : FtRapi.encodeStr( this.physical_adr_code ),
            input_date : FtRapi.encodeDate( this.input_date ),
            position_id : this.position_id,
            position_no : FtRapi.encodeStr( this.position_no ),
            celno : FtRapi.encodeStr( this.celno ),
            email : FtRapi.encodeStr( this.email ),
            expiry_date : FtRapi.encodeDate( this.expiry_date ),
            photo : FtRapi.encodeStr( this.photo ),
            photoz_id : this.photoz_id,
            application_date : FtRapi.encodeDate( this.application_date ),
            notes : FtRapi.encodeStr( this.notes ),
            file_date : FtRapi.encodeStr( this.file_date ),
            is_filed : FtRapi.encodeBool( this.is_filed ),
            move_no : FtRapi.encodeStr( this.move_no ),
            last_updated : FtRapi.encodeDttm( this.last_updated ),
            add_change_date : FtRapi.encodeDttm( this.add_change_date ),
            preferred_addressee : FtRapi.encodeBool( this.preferred_addressee ),
            ge_comms : FtRapi.encodeBool( this.ge_comms ),
            special_start_date : FtRapi.encodeDate( this.special_start_date ),
            special_end_date : FtRapi.encodeDate( this.special_end_date ),
            special_reason : FtRapi.encodeStr( this.special_reason ),
            file_no : FtRapi.encodeStr( this.file_no ),
            bin : FtRapi.encodeStr( this.bin ),
            seconded_to : FtRapi.encodeStr( this.seconded_to ),
            seconded_report_date : FtRapi.encodeDate( this.seconded_report_date ),
            called_date : FtRapi.encodeDate( this.called_date ),
            accepted_date : FtRapi.encodeDate( this.accepted_date ),
            seconded_date : FtRapi.encodeDate( this.seconded_date ),
            country : FtRapi.encodeStr( this.country ),
            justice_case_active : FtRapi.encodeBool( this.justice_case_active ),
            last_edited : FtRapi.encodeStr( this.last_edited ),
            field1_text : FtRapi.encodeStr( this.field1_text ),
            field2_num : FtRapi.encodeStr( this.field2_num ),
            field3_date : FtRapi.encodeDate( this.field3_date ),
            field_text2 : FtRapi.encodeStr( this.field_text2 ),
            field_text3 : FtRapi.encodeStr( this.field_text3 ),
            field_text4 : FtRapi.encodeStr( this.field_text4 ),
            field_text5 : FtRapi.encodeStr( this.field_text5 ),
            pastor_own : FtRapi.encodeStr( this.pastor_own ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.pastid = Ft.parseInt0Null( src.pastid );
        this.member_no = src.member_no;
        this.first_name = src.first_name;
        this.surname = src.surname;
        this.maiden_name = src.maiden_name;
        this.initials = src.initials;
        this.personal_title = src.personal_title;
        this.title_id = Ft.parseInt0Null( src.title_id );
        this.idno = src.idno;
        this.gender_id = Ft.parseInt0Null( src.gender_id );
        this.gender_name = src.gender_name;
        this.birth_date = src.birth_date;
        this.birth_place = src.birth_place;
        this.death_date = src.death_date;
        this.instruments = src.instruments;
        this.spouse_member_no = src.spouse_member_no;
        this.spouse_first_name = src.spouse_first_name;
        this.spouse_surname = src.spouse_surname;
        this.spouse_maiden_name = src.spouse_maiden_name;
        this.spouse_initials = src.spouse_initials;
        this.spouse_personal_title = src.spouse_personal_title;
        this.spouse_idno = src.spouse_idno;
        this.spouse_birth_date = src.spouse_birth_date;
        this.spouse_birth_place = src.spouse_birth_place;
        this.spouse_death_date = src.spouse_death_date;
        this.spouse_talents = src.spouse_talents;
        this.phone_home = src.phone_home;
        this.fax_home = src.fax_home;
        this.phone_work = src.phone_work;
        this.postal_adr_1 = src.postal_adr_1;
        this.postal_adr_2 = src.postal_adr_2;
        this.postal_adr_city = src.postal_adr_city;
        this.postal_adr_code = src.postal_adr_code;
        this.postal_adr_country = src.postal_adr_country;
        this.assembly_id = Ft.parseInt0Null( src.assembly_id );
        this.assembly_name = src.assembly_name;
        this.assembly_no = src.assembly_no;
        this.region_id = Ft.parseInt0Null( src.region_id );
        this.region_no = src.region_no;
        this.pastor_status = src.pastor_status;
        this.children_count = Ft.parseInt0Null( src.children_count );
        this.marital_status_id = Ft.parseInt0Null( src.marital_status_id );
        this.marital_status = src.marital_status;
        this.wedding_date = src.wedding_date;
        this.fulltime_date = src.fulltime_date;
        this.marriage_officiant_date = src.marriage_officiant_date;
        this.q_number = src.q_number;
        this.ordained_date = src.ordained_date;
        this.legitimize_date = src.legitimize_date;
        this.preachers_panel_tv_date = src.preachers_panel_tv_date;
        this.preachers_panel_radio_date = src.preachers_panel_radio_date;
        this.phone_extension = src.phone_extension;
        this.phone_request_line = Ft.parseBool( src.phone_request_line );
        this.remarks = src.remarks;
        this.language_preference = src.language_preference;
        this.physical_adr_1 = src.physical_adr_1;
        this.physical_adr_2 = src.physical_adr_2;
        this.physical_adr_city = src.physical_adr_city;
        this.physical_adr_code = src.physical_adr_code;
        this.input_date = src.input_date;
        this.position_id = Ft.parseInt0Null( src.position_id );
        this.position_no = src.position_no;
        this.celno = src.celno;
        this.email = src.email;
        this.expiry_date = src.expiry_date;
        this.photo = src.photo;
        this.photoz_id = Ft.parseInt0Null( src.photoz_id );
        this.application_date = src.application_date;
        this.notes = src.notes;
        this.file_date = src.file_date;
        this.is_filed = Ft.parseBool( src.is_filed );
        this.move_no = src.move_no;
        this.last_updated = src.last_updated;
        this.add_change_date = src.add_change_date;
        this.preferred_addressee = Ft.parseBool( src.preferred_addressee );
        this.ge_comms = Ft.parseBool( src.ge_comms );
        this.special_start_date = src.special_start_date;
        this.special_end_date = src.special_end_date;
        this.special_reason = src.special_reason;
        this.file_no = src.file_no;
        this.bin = src.bin;
        this.seconded_to = src.seconded_to;
        this.seconded_report_date = src.seconded_report_date;
        this.called_date = src.called_date;
        this.accepted_date = src.accepted_date;
        this.seconded_date = src.seconded_date;
        this.country = src.country;
        this.justice_case_active = Ft.parseBool( src.justice_case_active );
        this.last_edited = src.last_edited;
        this.field1_text = src.field1_text;
        this.field2_num = src.field2_num;
        this.field3_date = src.field3_date;
        this.field_text2 = src.field_text2;
        this.field_text3 = src.field_text3;
        this.field_text4 = src.field_text4;
        this.field_text5 = src.field_text5;
        this.pastor_own = src.pastor_own;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.pastid = null;
        this.member_no = null;
        this.first_name = null;
        this.surname = null;
        this.maiden_name = null;
        this.initials = null;
        this.personal_title = null;
        this.title_id = null;
        this.idno = null;
        this.gender_id = null;
        this.gender_name = null;
        this.birth_date = moment( '0001-01-01' ).toDate();
        this.birth_place = null;
        this.death_date = moment( '0001-01-01' ).toDate();
        this.instruments = null;
        this.spouse_member_no = null;
        this.spouse_first_name = null;
        this.spouse_surname = null;
        this.spouse_maiden_name = null;
        this.spouse_initials = null;
        this.spouse_personal_title = null;
        this.spouse_idno = null;
        this.spouse_birth_date = moment( '0001-01-01' ).toDate();
        this.spouse_birth_place = null;
        this.spouse_death_date = moment( '0001-01-01' ).toDate();
        this.spouse_talents = null;
        this.phone_home = null;
        this.fax_home = null;
        this.phone_work = null;
        this.postal_adr_1 = null;
        this.postal_adr_2 = null;
        this.postal_adr_city = null;
        this.postal_adr_code = null;
        this.postal_adr_country = null;
        this.assembly_id = null;
        this.assembly_name = null;
        this.assembly_no = null;
        this.region_id = null;
        this.region_no = null;
        this.pastor_status = null;
        this.children_count = null;
        this.marital_status_id = null;
        this.marital_status = null;
        this.wedding_date = moment( '0001-01-01' ).toDate();
        this.fulltime_date = moment( '0001-01-01' ).toDate();
        this.marriage_officiant_date = moment( '0001-01-01' ).toDate();
        this.q_number = null;
        this.ordained_date = moment( '0001-01-01' ).toDate();
        this.legitimize_date = moment( '0001-01-01' ).toDate();
        this.preachers_panel_tv_date = moment( '0001-01-01' ).toDate();
        this.preachers_panel_radio_date = moment( '0001-01-01' ).toDate();
        this.phone_extension = null;
        this.phone_request_line = false;
        this.remarks = null;
        this.language_preference = null;
        this.physical_adr_1 = null;
        this.physical_adr_2 = null;
        this.physical_adr_city = null;
        this.physical_adr_code = null;
        this.input_date = moment( '0001-01-01' ).toDate();
        this.position_id = null;
        this.position_no = null;
        this.celno = null;
        this.email = null;
        this.expiry_date = moment( '0001-01-01' ).toDate();
        this.photo = null;
        this.photoz_id = null;
        this.application_date = moment( '0001-01-01' ).toDate();
        this.notes = null;
        this.file_date = null;
        this.is_filed = false;
        this.move_no = null;
        this.last_updated = moment( '0001-01-01 00:00:00' ).toDate();
        this.add_change_date = moment( '0001-01-01 00:00:00' ).toDate();
        this.preferred_addressee = false;
        this.ge_comms = false;
        this.special_start_date = moment( '0001-01-01' ).toDate();
        this.special_end_date = moment( '0001-01-01' ).toDate();
        this.special_reason = null;
        this.file_no = null;
        this.bin = null;
        this.seconded_to = null;
        this.seconded_report_date = moment( '0001-01-01' ).toDate();
        this.called_date = moment( '0001-01-01' ).toDate();
        this.accepted_date = moment( '0001-01-01' ).toDate();
        this.seconded_date = moment( '0001-01-01' ).toDate();
        this.country = null;
        this.justice_case_active = false;
        this.last_edited = null;
        this.field1_text = null;
        this.field2_num = null;
        this.field3_date = moment( '0001-01-01' ).toDate();
        this.field_text2 = null;
        this.field_text3 = null;
        this.field_text4 = null;
        this.field_text5 = null;
        this.pastor_own = null;
    }
}
