import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// AssemblyBase class : Generated : DO NOT MODIFY
export default class AssemblyBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    gemid: number|null;
    assembly_no: string|null;
    assembly_name: string|null;
    type_id: number|null;
    region_id: number|null;
    region_no: string|null;
    region_name: string|null;
    physical_adr_1: string|null;
    physical_adr_2: string|null;
    physical_adr_city: string|null;
    physical_adr_code: string|null;
    postal_adr_1: string|null;
    postal_adr_2: string|null;
    postal_adr_city: string|null;
    postal_code: string|null;
    asset_register: boolean|null;
    fax_no: string|null;
    phone_no: string|null;
    phone_extension: string|null;
    procedure: string|null;
    bank: string|null;
    bank_branch: string|null;
    bank_account: string|null;
    bank_account_type: string|null;
    treasurer_name: string|null;
    treasurer_phone_extension: string|null;
    treasurer_procedure: string|null;
    treasurer_phone: string|null;
    treasurer_postal_adr_1: string|null;
    treasurer_postal_adr_2: string|null;
    treasurer_postal_adr_city: string|null;
    treasurer_postal_code: string|null;
    secretary_name: string|null;
    secretary_phone_extension: string|null;
    secretary_procedure: string|null;
    secretary_phone: string|null;
    secretary_postal_adr_1: string|null;
    secretary_postal_adr_2: string|null;
    secretary_postal_adr_city: string|null;
    secretary_postal_adr_code: string|null;
    input_date: Date|null;
    vicechairperson_name: string|null;
    vicechairperson_phone_extension: string|null;
    vicechairperson_procedure: string|null;
    vicechairperson_phone: string|null;
    vicechairperson_postal_adr_1: string|null;
    vicechairperson_postal_adr_2: string|null;
    vicechairperson_postal_adr_city: string|null;
    vicechairperson_postal_adr_code: string|null;
    main_pastor: string|null;
    pentecost_pastor: number|null;
    file_date: Date|null;
    is_filed: boolean|null;
    move_no: string|null;
    last_updated: Date|null;
    email: string|null;
    website: string|null;
    date_registered: Date|null;
    census_date: Date|null;
    census_women_count: string|null;
    census_men_count: string|null;
    census_youth_count: string|null;
    census_children_count: string|null;
    spoken_languages: string|null;
    title_deed: boolean|null;
    baptised_water_count: number|null;
    baptised_spirit_count: number|null;
    attending_sunday_service_count: number|null;
    attending_sunday_school_count: number|null;
    attending_over_35_count: number|null;
    attending_under_35_count: number|null;
    attending_other_count: number|null;
    audit_books: boolean|null;
    assembly_policy: boolean|null;
    assembly_gb: boolean|null;
    site_no_building: boolean|null;
    site_and_building: boolean|null;
    no_property: boolean|null;
    stat_funds_months: number|null;
    ordained_pastors_count: number|null;
    non_ordained_pastors_count: number|null;
    assembly_leader: boolean|null;
    service_contract: boolean|null;
    branch_assemblies_count: number|null;
    nlf: boolean|null;
    notes: string|null;
    last_edited_by_id: number|null;
    last_edited: string|null;
    web_usedid: string|null;
    scode: string|null;
    gps_lat: string|null;
    gps_long: string|null;
    bin: string|null;
    justice_case_active: boolean|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.gemid = 0;
        this.assembly_no = '';
        this.assembly_name = '';
        this.type_id = 0;
        this.region_id = 0;
        this.region_no = '';
        this.region_name = '';
        this.physical_adr_1 = '';
        this.physical_adr_2 = '';
        this.physical_adr_city = '';
        this.physical_adr_code = '';
        this.postal_adr_1 = '';
        this.postal_adr_2 = '';
        this.postal_adr_city = '';
        this.postal_code = '';
        this.asset_register = false;
        this.fax_no = '';
        this.phone_no = '';
        this.phone_extension = '';
        this.procedure = '';
        this.bank = '';
        this.bank_branch = '';
        this.bank_account = '';
        this.bank_account_type = '';
        this.treasurer_name = '';
        this.treasurer_phone_extension = '';
        this.treasurer_procedure = '';
        this.treasurer_phone = '';
        this.treasurer_postal_adr_1 = '';
        this.treasurer_postal_adr_2 = '';
        this.treasurer_postal_adr_city = '';
        this.treasurer_postal_code = '';
        this.secretary_name = '';
        this.secretary_phone_extension = '';
        this.secretary_procedure = '';
        this.secretary_phone = '';
        this.secretary_postal_adr_1 = '';
        this.secretary_postal_adr_2 = '';
        this.secretary_postal_adr_city = '';
        this.secretary_postal_adr_code = '';
        this.input_date = moment( '0001-01-01' ).toDate();
        this.vicechairperson_name = '';
        this.vicechairperson_phone_extension = '';
        this.vicechairperson_procedure = '';
        this.vicechairperson_phone = '';
        this.vicechairperson_postal_adr_1 = '';
        this.vicechairperson_postal_adr_2 = '';
        this.vicechairperson_postal_adr_city = '';
        this.vicechairperson_postal_adr_code = '';
        this.main_pastor = '';
        this.pentecost_pastor = 0;
        this.file_date = moment( '0001-01-01' ).toDate();
        this.is_filed = false;
        this.move_no = '';
        this.last_updated = moment( '0001-01-01 00:00:00' ).toDate();
        this.email = '';
        this.website = '';
        this.date_registered = moment( '0001-01-01' ).toDate();
        this.census_date = moment( '0001-01-01' ).toDate();
        this.census_women_count = '';
        this.census_men_count = '';
        this.census_youth_count = '';
        this.census_children_count = '';
        this.spoken_languages = '';
        this.title_deed = false;
        this.baptised_water_count = 0;
        this.baptised_spirit_count = 0;
        this.attending_sunday_service_count = 0;
        this.attending_sunday_school_count = 0;
        this.attending_over_35_count = 0;
        this.attending_under_35_count = 0;
        this.attending_other_count = 0;
        this.audit_books = false;
        this.assembly_policy = false;
        this.assembly_gb = false;
        this.site_no_building = false;
        this.site_and_building = false;
        this.no_property = false;
        this.stat_funds_months = 0;
        this.ordained_pastors_count = 0;
        this.non_ordained_pastors_count = 0;
        this.assembly_leader = false;
        this.service_contract = false;
        this.branch_assemblies_count = 0;
        this.nlf = false;
        this.notes = '';
        this.last_edited_by_id = 0;
        this.last_edited = '';
        this.web_usedid = '';
        this.scode = '';
        this.gps_lat = '';
        this.gps_long = '';
        this.bin = '';
        this.justice_case_active = false;
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.gemid = obj.gemid;
        this.assembly_no = obj.assembly_no;
        this.assembly_name = obj.assembly_name;
        this.type_id = obj.type_id;
        this.region_id = obj.region_id;
        this.region_no = obj.region_no;
        this.region_name = obj.region_name;
        this.physical_adr_1 = obj.physical_adr_1;
        this.physical_adr_2 = obj.physical_adr_2;
        this.physical_adr_city = obj.physical_adr_city;
        this.physical_adr_code = obj.physical_adr_code;
        this.postal_adr_1 = obj.postal_adr_1;
        this.postal_adr_2 = obj.postal_adr_2;
        this.postal_adr_city = obj.postal_adr_city;
        this.postal_code = obj.postal_code;
        this.asset_register = obj.asset_register;
        this.fax_no = obj.fax_no;
        this.phone_no = obj.phone_no;
        this.phone_extension = obj.phone_extension;
        this.procedure = obj.procedure;
        this.bank = obj.bank;
        this.bank_branch = obj.bank_branch;
        this.bank_account = obj.bank_account;
        this.bank_account_type = obj.bank_account_type;
        this.treasurer_name = obj.treasurer_name;
        this.treasurer_phone_extension = obj.treasurer_phone_extension;
        this.treasurer_procedure = obj.treasurer_procedure;
        this.treasurer_phone = obj.treasurer_phone;
        this.treasurer_postal_adr_1 = obj.treasurer_postal_adr_1;
        this.treasurer_postal_adr_2 = obj.treasurer_postal_adr_2;
        this.treasurer_postal_adr_city = obj.treasurer_postal_adr_city;
        this.treasurer_postal_code = obj.treasurer_postal_code;
        this.secretary_name = obj.secretary_name;
        this.secretary_phone_extension = obj.secretary_phone_extension;
        this.secretary_procedure = obj.secretary_procedure;
        this.secretary_phone = obj.secretary_phone;
        this.secretary_postal_adr_1 = obj.secretary_postal_adr_1;
        this.secretary_postal_adr_2 = obj.secretary_postal_adr_2;
        this.secretary_postal_adr_city = obj.secretary_postal_adr_city;
        this.secretary_postal_adr_code = obj.secretary_postal_adr_code;
        this.input_date = FtRapi.decodeDate( obj.input_date );
        this.vicechairperson_name = obj.vicechairperson_name;
        this.vicechairperson_phone_extension = obj.vicechairperson_phone_extension;
        this.vicechairperson_procedure = obj.vicechairperson_procedure;
        this.vicechairperson_phone = obj.vicechairperson_phone;
        this.vicechairperson_postal_adr_1 = obj.vicechairperson_postal_adr_1;
        this.vicechairperson_postal_adr_2 = obj.vicechairperson_postal_adr_2;
        this.vicechairperson_postal_adr_city = obj.vicechairperson_postal_adr_city;
        this.vicechairperson_postal_adr_code = obj.vicechairperson_postal_adr_code;
        this.main_pastor = obj.main_pastor;
        this.pentecost_pastor = obj.pentecost_pastor;
        this.file_date = FtRapi.decodeDate( obj.file_date );
        this.is_filed = obj.is_filed;
        this.move_no = obj.move_no;
        this.last_updated = FtRapi.decodeDttm( obj.last_updated );
        this.email = obj.email;
        this.website = obj.website;
        this.date_registered = FtRapi.decodeDate( obj.date_registered );
        this.census_date = FtRapi.decodeDate( obj.census_date );
        this.census_women_count = obj.census_women_count;
        this.census_men_count = obj.census_men_count;
        this.census_youth_count = obj.census_youth_count;
        this.census_children_count = obj.census_children_count;
        this.spoken_languages = obj.spoken_languages;
        this.title_deed = obj.title_deed;
        this.baptised_water_count = obj.baptised_water_count;
        this.baptised_spirit_count = obj.baptised_spirit_count;
        this.attending_sunday_service_count = obj.attending_sunday_service_count;
        this.attending_sunday_school_count = obj.attending_sunday_school_count;
        this.attending_over_35_count = obj.attending_over_35_count;
        this.attending_under_35_count = obj.attending_under_35_count;
        this.attending_other_count = obj.attending_other_count;
        this.audit_books = obj.audit_books;
        this.assembly_policy = obj.assembly_policy;
        this.assembly_gb = obj.assembly_gb;
        this.site_no_building = obj.site_no_building;
        this.site_and_building = obj.site_and_building;
        this.no_property = obj.no_property;
        this.stat_funds_months = obj.stat_funds_months;
        this.ordained_pastors_count = obj.ordained_pastors_count;
        this.non_ordained_pastors_count = obj.non_ordained_pastors_count;
        this.assembly_leader = obj.assembly_leader;
        this.service_contract = obj.service_contract;
        this.branch_assemblies_count = obj.branch_assemblies_count;
        this.nlf = obj.nlf;
        this.notes = obj.notes;
        this.last_edited_by_id = obj.last_edited_by_id;
        this.last_edited = obj.last_edited;
        this.web_usedid = obj.web_usedid;
        this.scode = obj.scode;
        this.gps_lat = obj.gps_lat;
        this.gps_long = obj.gps_long;
        this.bin = obj.bin;
        this.justice_case_active = obj.justice_case_active;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            gemid : this.gemid,
            assembly_no : FtRapi.encodeStr( this.assembly_no ),
            assembly_name : FtRapi.encodeStr( this.assembly_name ),
            type_id : this.type_id,
            region_id : this.region_id,
            region_no : FtRapi.encodeStr( this.region_no ),
            region_name : FtRapi.encodeStr( this.region_name ),
            physical_adr_1 : FtRapi.encodeStr( this.physical_adr_1 ),
            physical_adr_2 : FtRapi.encodeStr( this.physical_adr_2 ),
            physical_adr_city : FtRapi.encodeStr( this.physical_adr_city ),
            physical_adr_code : FtRapi.encodeStr( this.physical_adr_code ),
            postal_adr_1 : FtRapi.encodeStr( this.postal_adr_1 ),
            postal_adr_2 : FtRapi.encodeStr( this.postal_adr_2 ),
            postal_adr_city : FtRapi.encodeStr( this.postal_adr_city ),
            postal_code : FtRapi.encodeStr( this.postal_code ),
            asset_register : FtRapi.encodeBool( this.asset_register ),
            fax_no : FtRapi.encodeStr( this.fax_no ),
            phone_no : FtRapi.encodeStr( this.phone_no ),
            phone_extension : FtRapi.encodeStr( this.phone_extension ),
            procedure : FtRapi.encodeStr( this.procedure ),
            bank : FtRapi.encodeStr( this.bank ),
            bank_branch : FtRapi.encodeStr( this.bank_branch ),
            bank_account : FtRapi.encodeStr( this.bank_account ),
            bank_account_type : FtRapi.encodeStr( this.bank_account_type ),
            treasurer_name : FtRapi.encodeStr( this.treasurer_name ),
            treasurer_phone_extension : FtRapi.encodeStr( this.treasurer_phone_extension ),
            treasurer_procedure : FtRapi.encodeStr( this.treasurer_procedure ),
            treasurer_phone : FtRapi.encodeStr( this.treasurer_phone ),
            treasurer_postal_adr_1 : FtRapi.encodeStr( this.treasurer_postal_adr_1 ),
            treasurer_postal_adr_2 : FtRapi.encodeStr( this.treasurer_postal_adr_2 ),
            treasurer_postal_adr_city : FtRapi.encodeStr( this.treasurer_postal_adr_city ),
            treasurer_postal_code : FtRapi.encodeStr( this.treasurer_postal_code ),
            secretary_name : FtRapi.encodeStr( this.secretary_name ),
            secretary_phone_extension : FtRapi.encodeStr( this.secretary_phone_extension ),
            secretary_procedure : FtRapi.encodeStr( this.secretary_procedure ),
            secretary_phone : FtRapi.encodeStr( this.secretary_phone ),
            secretary_postal_adr_1 : FtRapi.encodeStr( this.secretary_postal_adr_1 ),
            secretary_postal_adr_2 : FtRapi.encodeStr( this.secretary_postal_adr_2 ),
            secretary_postal_adr_city : FtRapi.encodeStr( this.secretary_postal_adr_city ),
            secretary_postal_adr_code : FtRapi.encodeStr( this.secretary_postal_adr_code ),
            input_date : FtRapi.encodeDate( this.input_date ),
            vicechairperson_name : FtRapi.encodeStr( this.vicechairperson_name ),
            vicechairperson_phone_extension : FtRapi.encodeStr( this.vicechairperson_phone_extension ),
            vicechairperson_procedure : FtRapi.encodeStr( this.vicechairperson_procedure ),
            vicechairperson_phone : FtRapi.encodeStr( this.vicechairperson_phone ),
            vicechairperson_postal_adr_1 : FtRapi.encodeStr( this.vicechairperson_postal_adr_1 ),
            vicechairperson_postal_adr_2 : FtRapi.encodeStr( this.vicechairperson_postal_adr_2 ),
            vicechairperson_postal_adr_city : FtRapi.encodeStr( this.vicechairperson_postal_adr_city ),
            vicechairperson_postal_adr_code : FtRapi.encodeStr( this.vicechairperson_postal_adr_code ),
            main_pastor : FtRapi.encodeStr( this.main_pastor ),
            pentecost_pastor : this.pentecost_pastor,
            file_date : FtRapi.encodeDate( this.file_date ),
            is_filed : FtRapi.encodeBool( this.is_filed ),
            move_no : FtRapi.encodeStr( this.move_no ),
            last_updated : FtRapi.encodeDttm( this.last_updated ),
            email : FtRapi.encodeStr( this.email ),
            website : FtRapi.encodeStr( this.website ),
            date_registered : FtRapi.encodeDate( this.date_registered ),
            census_date : FtRapi.encodeDate( this.census_date ),
            census_women_count : FtRapi.encodeStr( this.census_women_count ),
            census_men_count : FtRapi.encodeStr( this.census_men_count ),
            census_youth_count : FtRapi.encodeStr( this.census_youth_count ),
            census_children_count : FtRapi.encodeStr( this.census_children_count ),
            spoken_languages : FtRapi.encodeStr( this.spoken_languages ),
            title_deed : FtRapi.encodeBool( this.title_deed ),
            baptised_water_count : this.baptised_water_count,
            baptised_spirit_count : this.baptised_spirit_count,
            attending_sunday_service_count : this.attending_sunday_service_count,
            attending_sunday_school_count : this.attending_sunday_school_count,
            attending_over_35_count : this.attending_over_35_count,
            attending_under_35_count : this.attending_under_35_count,
            attending_other_count : this.attending_other_count,
            audit_books : FtRapi.encodeBool( this.audit_books ),
            assembly_policy : FtRapi.encodeBool( this.assembly_policy ),
            assembly_gb : FtRapi.encodeBool( this.assembly_gb ),
            site_no_building : FtRapi.encodeBool( this.site_no_building ),
            site_and_building : FtRapi.encodeBool( this.site_and_building ),
            no_property : FtRapi.encodeBool( this.no_property ),
            stat_funds_months : this.stat_funds_months,
            ordained_pastors_count : this.ordained_pastors_count,
            non_ordained_pastors_count : this.non_ordained_pastors_count,
            assembly_leader : FtRapi.encodeBool( this.assembly_leader ),
            service_contract : FtRapi.encodeBool( this.service_contract ),
            branch_assemblies_count : this.branch_assemblies_count,
            nlf : FtRapi.encodeBool( this.nlf ),
            notes : FtRapi.encodeStr( this.notes ),
            last_edited_by_id : this.last_edited_by_id,
            last_edited : FtRapi.encodeStr( this.last_edited ),
            web_usedid : FtRapi.encodeStr( this.web_usedid ),
            scode : FtRapi.encodeStr( this.scode ),
            gps_lat : FtRapi.encodeStr( this.gps_lat ),
            gps_long : FtRapi.encodeStr( this.gps_long ),
            bin : FtRapi.encodeStr( this.bin ),
            justice_case_active : FtRapi.encodeBool( this.justice_case_active ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.gemid = Ft.parseInt0Null( src.gemid );
        this.assembly_no = src.assembly_no;
        this.assembly_name = src.assembly_name;
        this.type_id = Ft.parseInt0Null( src.type_id );
        this.region_id = Ft.parseInt0Null( src.region_id );
        this.region_no = src.region_no;
        this.region_name = src.region_name;
        this.physical_adr_1 = src.physical_adr_1;
        this.physical_adr_2 = src.physical_adr_2;
        this.physical_adr_city = src.physical_adr_city;
        this.physical_adr_code = src.physical_adr_code;
        this.postal_adr_1 = src.postal_adr_1;
        this.postal_adr_2 = src.postal_adr_2;
        this.postal_adr_city = src.postal_adr_city;
        this.postal_code = src.postal_code;
        this.asset_register = Ft.parseBool( src.asset_register );
        this.fax_no = src.fax_no;
        this.phone_no = src.phone_no;
        this.phone_extension = src.phone_extension;
        this.procedure = src.procedure;
        this.bank = src.bank;
        this.bank_branch = src.bank_branch;
        this.bank_account = src.bank_account;
        this.bank_account_type = src.bank_account_type;
        this.treasurer_name = src.treasurer_name;
        this.treasurer_phone_extension = src.treasurer_phone_extension;
        this.treasurer_procedure = src.treasurer_procedure;
        this.treasurer_phone = src.treasurer_phone;
        this.treasurer_postal_adr_1 = src.treasurer_postal_adr_1;
        this.treasurer_postal_adr_2 = src.treasurer_postal_adr_2;
        this.treasurer_postal_adr_city = src.treasurer_postal_adr_city;
        this.treasurer_postal_code = src.treasurer_postal_code;
        this.secretary_name = src.secretary_name;
        this.secretary_phone_extension = src.secretary_phone_extension;
        this.secretary_procedure = src.secretary_procedure;
        this.secretary_phone = src.secretary_phone;
        this.secretary_postal_adr_1 = src.secretary_postal_adr_1;
        this.secretary_postal_adr_2 = src.secretary_postal_adr_2;
        this.secretary_postal_adr_city = src.secretary_postal_adr_city;
        this.secretary_postal_adr_code = src.secretary_postal_adr_code;
        this.input_date = src.input_date;
        this.vicechairperson_name = src.vicechairperson_name;
        this.vicechairperson_phone_extension = src.vicechairperson_phone_extension;
        this.vicechairperson_procedure = src.vicechairperson_procedure;
        this.vicechairperson_phone = src.vicechairperson_phone;
        this.vicechairperson_postal_adr_1 = src.vicechairperson_postal_adr_1;
        this.vicechairperson_postal_adr_2 = src.vicechairperson_postal_adr_2;
        this.vicechairperson_postal_adr_city = src.vicechairperson_postal_adr_city;
        this.vicechairperson_postal_adr_code = src.vicechairperson_postal_adr_code;
        this.main_pastor = src.main_pastor;
        this.pentecost_pastor = Ft.parseInt0Null( src.pentecost_pastor );
        this.file_date = src.file_date;
        this.is_filed = Ft.parseBool( src.is_filed );
        this.move_no = src.move_no;
        this.last_updated = src.last_updated;
        this.email = src.email;
        this.website = src.website;
        this.date_registered = src.date_registered;
        this.census_date = src.census_date;
        this.census_women_count = src.census_women_count;
        this.census_men_count = src.census_men_count;
        this.census_youth_count = src.census_youth_count;
        this.census_children_count = src.census_children_count;
        this.spoken_languages = src.spoken_languages;
        this.title_deed = Ft.parseBool( src.title_deed );
        this.baptised_water_count = Ft.parseInt0Null( src.baptised_water_count );
        this.baptised_spirit_count = Ft.parseInt0Null( src.baptised_spirit_count );
        this.attending_sunday_service_count = Ft.parseInt0Null( src.attending_sunday_service_count );
        this.attending_sunday_school_count = Ft.parseInt0Null( src.attending_sunday_school_count );
        this.attending_over_35_count = Ft.parseInt0Null( src.attending_over_35_count );
        this.attending_under_35_count = Ft.parseInt0Null( src.attending_under_35_count );
        this.attending_other_count = Ft.parseInt0Null( src.attending_other_count );
        this.audit_books = Ft.parseBool( src.audit_books );
        this.assembly_policy = Ft.parseBool( src.assembly_policy );
        this.assembly_gb = Ft.parseBool( src.assembly_gb );
        this.site_no_building = Ft.parseBool( src.site_no_building );
        this.site_and_building = Ft.parseBool( src.site_and_building );
        this.no_property = Ft.parseBool( src.no_property );
        this.stat_funds_months = Ft.parseInt0Null( src.stat_funds_months );
        this.ordained_pastors_count = Ft.parseInt0Null( src.ordained_pastors_count );
        this.non_ordained_pastors_count = Ft.parseInt0Null( src.non_ordained_pastors_count );
        this.assembly_leader = Ft.parseBool( src.assembly_leader );
        this.service_contract = Ft.parseBool( src.service_contract );
        this.branch_assemblies_count = Ft.parseInt0Null( src.branch_assemblies_count );
        this.nlf = Ft.parseBool( src.nlf );
        this.notes = src.notes;
        this.last_edited_by_id = Ft.parseInt0Null( src.last_edited_by_id );
        this.last_edited = src.last_edited;
        this.web_usedid = src.web_usedid;
        this.scode = src.scode;
        this.gps_lat = src.gps_lat;
        this.gps_long = src.gps_long;
        this.bin = src.bin;
        this.justice_case_active = Ft.parseBool( src.justice_case_active );
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.gemid = null;
        this.assembly_no = null;
        this.assembly_name = null;
        this.type_id = null;
        this.region_id = null;
        this.region_no = null;
        this.region_name = null;
        this.physical_adr_1 = null;
        this.physical_adr_2 = null;
        this.physical_adr_city = null;
        this.physical_adr_code = null;
        this.postal_adr_1 = null;
        this.postal_adr_2 = null;
        this.postal_adr_city = null;
        this.postal_code = null;
        this.asset_register = false;
        this.fax_no = null;
        this.phone_no = null;
        this.phone_extension = null;
        this.procedure = null;
        this.bank = null;
        this.bank_branch = null;
        this.bank_account = null;
        this.bank_account_type = null;
        this.treasurer_name = null;
        this.treasurer_phone_extension = null;
        this.treasurer_procedure = null;
        this.treasurer_phone = null;
        this.treasurer_postal_adr_1 = null;
        this.treasurer_postal_adr_2 = null;
        this.treasurer_postal_adr_city = null;
        this.treasurer_postal_code = null;
        this.secretary_name = null;
        this.secretary_phone_extension = null;
        this.secretary_procedure = null;
        this.secretary_phone = null;
        this.secretary_postal_adr_1 = null;
        this.secretary_postal_adr_2 = null;
        this.secretary_postal_adr_city = null;
        this.secretary_postal_adr_code = null;
        this.input_date = moment( '0001-01-01' ).toDate();
        this.vicechairperson_name = null;
        this.vicechairperson_phone_extension = null;
        this.vicechairperson_procedure = null;
        this.vicechairperson_phone = null;
        this.vicechairperson_postal_adr_1 = null;
        this.vicechairperson_postal_adr_2 = null;
        this.vicechairperson_postal_adr_city = null;
        this.vicechairperson_postal_adr_code = null;
        this.main_pastor = null;
        this.pentecost_pastor = null;
        this.file_date = moment( '0001-01-01' ).toDate();
        this.is_filed = false;
        this.move_no = null;
        this.last_updated = moment( '0001-01-01 00:00:00' ).toDate();
        this.email = null;
        this.website = null;
        this.date_registered = moment( '0001-01-01' ).toDate();
        this.census_date = moment( '0001-01-01' ).toDate();
        this.census_women_count = null;
        this.census_men_count = null;
        this.census_youth_count = null;
        this.census_children_count = null;
        this.spoken_languages = null;
        this.title_deed = false;
        this.baptised_water_count = null;
        this.baptised_spirit_count = null;
        this.attending_sunday_service_count = null;
        this.attending_sunday_school_count = null;
        this.attending_over_35_count = null;
        this.attending_under_35_count = null;
        this.attending_other_count = null;
        this.audit_books = false;
        this.assembly_policy = false;
        this.assembly_gb = false;
        this.site_no_building = false;
        this.site_and_building = false;
        this.no_property = false;
        this.stat_funds_months = null;
        this.ordained_pastors_count = null;
        this.non_ordained_pastors_count = null;
        this.assembly_leader = false;
        this.service_contract = false;
        this.branch_assemblies_count = null;
        this.nlf = false;
        this.notes = null;
        this.last_edited_by_id = null;
        this.last_edited = null;
        this.web_usedid = null;
        this.scode = null;
        this.gps_lat = null;
        this.gps_long = null;
        this.bin = null;
        this.justice_case_active = false;
    }
}
