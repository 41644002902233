import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// PastorTitleBase class : Generated : DO NOT MODIFY
export default class PastorTitleBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    title: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.title = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.title = obj.title;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            title : FtRapi.encodeStr( this.title ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.title = src.title;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.title = null;
    }
}
