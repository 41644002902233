import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtQrScanner from '../first/ftQrScanner';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup     from '../models/genLookup';
import Attendee      from '../models/attendee';

//********************************************************************
// PageConfrecepGoodyScan
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageConfrecepGoodyScan( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup         , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy          , setBusy         ] = useState( false );
    const [ mdlAtt        , setMdlAtt       ] = useState<Attendee|null>( null );
    const [ ren           , setRen          ] = useState<string>( Ft.randomStr() ); // use to force rerender
    const [ mdlAtt2       , setMdlAtt2      ] = useState( new Attendee() );
    const [ lkpAssembly   , setLkpAssembly  ] = useState<GenLookup[]>( [] );
    const [ lkpRegion     , setLkpRegion    ] = useState<GenLookup[]>( [] );
    const [ lkpUserz      , setLkpUserz     ] = useState<GenLookup[]>( [] );
    const [ formKey       , setFormKey      ] = useState<string>( Ft.randomStr() ); // use to force reset form
    const [ info          , setInfo         ] = useState<any>({
        msgReceive    : 'Ready',
        clrReceive    : '#b29075',
    });
    const resetTime = 5000;

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( lkpAssembly, lkpRegion, lkpUserz ) ) return ftBusy( { popup: popup } );

    const stlFrame: any = {
        ...Th.frame,
        ss : '12|12|12|12|9',
    };
    const stlAttInfo: any = {
        ss : '12|4',
        txtDescr: '18,WCO#404040',
    };
    const stlMsg: any = {
        txtDescr: '50,WCO#ffffff',
    };
    const stlBox: any = {
        bor: '2,#01457d',
        pad: '10',
    };

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Scan Goody-Bag' {...stlFrame}>
            <FtCon ss='12|12|12|12|8' h='100' md='rw'>
                <FtCon ss='12|6' md='rw' lt='c,c' rat={1.5} bor='2,#01457d'>
                    <FtQrScanner ss='12' rat={1.5} onScan={setReception} distReset={resetTime} distinct={true} />
                </FtCon>
                <FtCon ss='12|6' md='rw' lt='c,a' bkgClr={info.clrReceive} {...stlBox}>
                    <FtText {...stlMsg} text={info.msgReceive} />
                </FtCon>
            </FtCon>
            <FtCon ss='12' md='rw' pad={5} bor='2,#01457d'>
                <FtText {...stlAttInfo} text={`Surname : ${mdlAtt?.surname ?? ''}`} />
                <FtText {...stlAttInfo} text={`Fullnames : ${mdlAtt?.fullnames ?? ''}`} />
                <FtText {...stlAttInfo} text={`RSA-ID No : ${mdlAtt?.rsaid_no ?? ''}`} />
                <FtText {...stlAttInfo} text={`Email : ${mdlAtt?.email ?? ''}`} />
                <FtText {...stlAttInfo} text={`Cell No : ${mdlAtt?.cell_no ?? ''}`} />
                <FtText {...stlAttInfo} text={`Assembly : ${lkpAssembly.find( x => x.id == mdlAtt?.assembly_id )?.name ?? ''}`} />
                <FtText {...stlAttInfo} text={`Region : ${lkpRegion.find( x => x.id == mdlAtt?.region_id )?.name ?? ''}`} />
                <FtText {...stlAttInfo} text={`Pay Method : ${Ut.lkpPayMethod!.find( x => x.id == mdlAtt?.payment_method_id )?.name ?? ''}`} />
                <FtCon ss='12' h={10} />
            </FtCon>
        </FtFrame>

        <FtFrame text='Manually Receive By RSA-ID No' {...stlFrame}>
            <FtForm key={formKey} ss='12' md='rw'  pad={5} spacing={5} elev={10} bor='2,#01457d'
                models={{ m: mdlAtt2 }}
                onSubmit={()=> setReception( mdlAtt2.rsaid_no! )}
            >
                 <FtFmTextField ss='12|6' name='m.rsaid_no' label='RSA-ID No' />
                 <FtFmSubmit ss='3:6:3|9:3' label='Receive Attendee' marT={6} clr='#dc004e' busy={busy} />
            </FtForm>
        </FtFrame>

     </FtCanvas>);


    //====================================================================
    // event handlers

    function onLoad(): void
    {
        //- load lookups -----
        setBusy( true );

        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setLkpRegion( data[1] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });

        FtRapi.callList( 'userzLookup', {
            success: ( result, data ) => setLkpUserz( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });

        Ut.loadLookups( {
            success: ( result, data ) => setBusy( false ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
    }

    function setReception( data: string ): void
    {
        FtRapi.callNew( 'ConfrecepGoodyScan', {
            urlParms: { rsaid_no: data },
            success: ( result, data ) => {
                if ( data[1] == 1 ) {             // received OK
                    info.clrReceive = '#008000';
                    info.msgReceive = 'Received OK';
                } else {                          // already received previously
                    info.clrReceive = '#ff0000';
                    info.msgReceive 
                        = 'Already scanned\n'
                        + `on '${Ft.dtFmt( data[0].goodybag_scan_dttm, 'D MMMM' )}'\n`
                        + `at '${Ft.tmFmtMin( data[0].goodybag_scan_dttm )}'\n`
                        + `by '${lkpUserz.find( x => x.id == data[0].goodybag_scan_user_id )?.name ?? 'Unkown'}'`;
                }
                setMdlAtt( data[0] );
            },
            error: ( { message } ) => {
                info.msgReceive = message;
                info.msgBkg = '#ff0000';
                info.msgPay = '';
                setMdlAtt( new Attendee() );
            },
            complete: () => {
                setBusy( false );
                setTimeout( resetDisplay, resetTime );
            }
        });
    }

    function resetDisplay(): void
    {
        info.msgReceive = 'Ready';
        info.clrReceive = '#b29075';
        setMdlAtt( new Attendee() );
        setFormKey( Ft.randomStr() );
    }
}

