import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// ArcRepoMediaTypeBase class : Generated : DO NOT MODIFY
export default class ArcRepoMediaTypeBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    code: string|null;
    name: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.code = '';
        this.name = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.code = obj.code;
        this.name = obj.name;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            code : FtRapi.encodeStr( this.code ),
            name : FtRapi.encodeStr( this.name ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.code = src.code;
        this.name = src.name;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.code = null;
        this.name = null;
    }
}
