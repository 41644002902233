import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmSearchField from '../first/ftFmSearchField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup                          from '../models/genLookup';
import Pastor                             from '../models/pastor';


//********************************************************************
// PagePastorSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PagePastorSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ mdlPastor              , setMdlPastor              ] = useState( new Pastor() );
    const [ lkpAssembly            , setLkpAssembly            ] = useState<GenLookup[]>( [] );
    const [ lkpRegion              , setLkpRegion              ] = useState<GenLookup[]>( [] );
    const [ lstPastor              , setLstPastor              ] = useState( [] as Pastor[] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );


    //====================================================================
    // render

    if ( busy || Ft.eAny( lkpAssembly, lkpRegion ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|9' md='rw' lt='z,a' marB={15}>
            <FtButton label="New Pastor" onTap={onTapPastorNew} {...Th.button} />
        </FtCon>

        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlPastor }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.surname'           label='Surname'         {...Th.field} />
                <FtFmTextField     name='m.first_name'        label='First Name'      {...Th.field} />
                <FtFmSearchField   name='m.assembly_id'       label='Assembly'        {...Th.field} data={lkpAssembly} />
                <FtFmSearchField   name='m.region_id'         label='Region'          {...Th.field} data={lkpRegion} />
                <FtFmTextField     name='m.member_no'         label='Member No'       {...Th.field} />
                <FtFmTextField     name='m.pastor_status'     label='Pastor Status'   {...Th.field} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstPastor}
                cols={[
                    { fld: ''                , hd: 'Edit'            , type: 'act'  , flex: 1,  fmt: 'mc-pencil'    , clr: '#808080', act: onTapPastorEdit },
                    { fld: ''                , hd: 'Qual'            , type: 'act'  , flex: 1,  fmt: 'ft-award'     , clr: '#808080', act: onTapPastorQuals },
                    { fld: ''                , hd: 'Folders'         , type: 'act'  , flex: 1,  fmt: 'mc-folder'    , clr: '#808080', act: onTapPastorFolder },
                    { fld: ''                , hd: 'Files'           , type: 'act'  , flex: 1,  fmt: 'mc-paperclip' , clr: '#808080', act: onTapPastorFiles },
                    { fld: 'member_no'       , hd: 'Member No'       , type: 'txt'  , flex: 3 },
                    { fld: 'initials'        , hd: 'Initials'        , type: 'txt'  , flex: 3 },
                    { fld: 'surname'         , hd: 'Surname'         , type: 'txt'  , flex: 3 },
                    { fld: 'first_name'      , hd: 'First Name'      , type: 'txt'  , flex: 3 },
                    { fld: 'phone_home'      , hd: 'Phone Home'      , type: 'txt'  , flex: 3 },
                    { fld: 'celno'           , hd: 'Celno'           , type: 'txt'  , flex: 3 },
                    { fld: 'assembly_id'     , hd: 'Assembly'        , type: 'lkp'  , flex: 3, lkp: lkpAssembly },
                    { fld: 'region_id'       , hd: 'Region'          , type: 'lkp'  , flex: 3, lkp: lkpRegion },
                    { fld: 'pastor_status'   , hd: 'Pastor Status'   , type: 'txt'  , flex: 3 },
                ]}
            />
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setLkpRegion( data[1] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),  
            complete: () => setBusy( false ),
        });
    }

    function onSearch(): void
    {
        setBusy( true );
        setLstPastor( [] );
        FtRapi.callList( 'pastorSearch', {
            urlParms: {
                prm_member_no     : mdlPastor.member_no,
                prm_surname       : mdlPastor.surname,
                prm_first_name    : mdlPastor.first_name,
                prm_assembly_id   : mdlPastor.assembly_id,
                prm_region_id     : mdlPastor.region_id,
                prm_pastor_status : mdlPastor.pastor_status,
            },
            success: ( result, data ) => setLstPastor( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapPastorEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'PastorEdit', { pastorId: dataId } );
    }

    function onTapPastorNew(): void
    {
        nav.push( 'PastorEdit', { pastorId: 0 } );
    }

    function onTapPastorQuals( { dataId }: FtDgAction ): void
    {
        nav.push( 'QualificationSearch', { pastorId: dataId } );
    }

    function onTapPastorFolder( { dataItem }: FtDgAction ): void
    {
        Ft.openUrl( `${Ut.pastorFolderLink}/${dataItem.member_no}` );
    }

    function onTapPastorFiles( { dataId }: FtDgAction ): void
    {
        nav.push( 'PastorFileSearch', { pastorId: dataId } );
    }
}
