import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// PastorPositionBase class : Generated : DO NOT MODIFY
export default class PastorPositionBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    ampid: number|null;
    position: string|null;
    position_no: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.ampid = 0;
        this.position = '';
        this.position_no = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.ampid = obj.ampid;
        this.position = obj.position;
        this.position_no = obj.position_no;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            ampid : this.ampid,
            position : FtRapi.encodeStr( this.position ),
            position_no : FtRapi.encodeStr( this.position_no ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.ampid = Ft.parseInt0Null( src.ampid );
        this.position = src.position;
        this.position_no = src.position_no;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.ampid = null;
        this.position = null;
        this.position_no = null;
    }
}
