import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// AttendeeBase class : Generated : DO NOT MODIFY
export default class AttendeeBase implements FtModel 
{
    //====================================================================
    // properties
    id: number|null;
    conference_id: number|null;
    surname: string|null;
    fullnames: string|null;
    title_id: number|null;
    rsaid_no: string|null;
    postal_adr_1: string|null;
    postal_adr_2: string|null;
    postal_adr_code: string|null;
    email: string|null;
    cell_no: string|null;
    phone: string|null;
    fax: string|null;
    assembly_id: number|null;
    province_id: number|null;
    region_id: number|null;
    attendee_type_id: number|null;
    contact_method_id: number|null;
    capture_dttm: Date|null;
    payment_date: Date|null;
    payment_online: boolean|null;
    payment: boolean|null;
    payment_amount: Decimal|null;
    payment_method_id: number|null;
    card_printed: boolean|null;
    is_committee: boolean|null;
    reg_status_id: number|null;
    goodybag_received: boolean|null;
    goodybag_scan_user_id: number|null;
    goodybag_scan_dttm: Date|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.conference_id = 0;
        this.surname = '';
        this.fullnames = '';
        this.title_id = 0;
        this.rsaid_no = '';
        this.postal_adr_1 = '';
        this.postal_adr_2 = '';
        this.postal_adr_code = '';
        this.email = '';
        this.cell_no = '';
        this.phone = '';
        this.fax = '';
        this.assembly_id = 0;
        this.province_id = 0;
        this.region_id = 0;
        this.attendee_type_id = 0;
        this.contact_method_id = 0;
        this.capture_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.payment_date = moment( '0001-01-01' ).toDate();
        this.payment_online = false;
        this.payment = false;
        this.payment_amount = new Decimal('0.0');
        this.payment_method_id = 0;
        this.card_printed = false;
        this.is_committee = false;
        this.reg_status_id = 0;
        this.goodybag_received = false;
        this.goodybag_scan_user_id = 0;
        this.goodybag_scan_dttm = moment( '0001-01-01 00:00:00' ).toDate();
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.conference_id = obj.conference_id;
        this.surname = obj.surname;
        this.fullnames = obj.fullnames;
        this.title_id = obj.title_id;
        this.rsaid_no = obj.rsaid_no;
        this.postal_adr_1 = obj.postal_adr_1;
        this.postal_adr_2 = obj.postal_adr_2;
        this.postal_adr_code = obj.postal_adr_code;
        this.email = obj.email;
        this.cell_no = obj.cell_no;
        this.phone = obj.phone;
        this.fax = obj.fax;
        this.assembly_id = obj.assembly_id;
        this.province_id = obj.province_id;
        this.region_id = obj.region_id;
        this.attendee_type_id = obj.attendee_type_id;
        this.contact_method_id = obj.contact_method_id;
        this.capture_dttm = FtRapi.decodeDttm( obj.capture_dttm );
        this.payment_date = FtRapi.decodeDate( obj.payment_date );
        this.payment_online = obj.payment_online;
        this.payment = obj.payment;
        this.payment_amount = FtRapi.decodeDec( obj.payment_amount );
        this.payment_method_id = obj.payment_method_id;
        this.card_printed = obj.card_printed;
        this.is_committee = obj.is_committee;
        this.reg_status_id = obj.reg_status_id;
        this.goodybag_received = obj.goodybag_received;
        this.goodybag_scan_user_id = obj.goodybag_scan_user_id;
        this.goodybag_scan_dttm = FtRapi.decodeDttm( obj.goodybag_scan_dttm );
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            conference_id : this.conference_id,
            surname : FtRapi.encodeStr( this.surname ),
            fullnames : FtRapi.encodeStr( this.fullnames ),
            title_id : this.title_id,
            rsaid_no : FtRapi.encodeStr( this.rsaid_no ),
            postal_adr_1 : FtRapi.encodeStr( this.postal_adr_1 ),
            postal_adr_2 : FtRapi.encodeStr( this.postal_adr_2 ),
            postal_adr_code : FtRapi.encodeStr( this.postal_adr_code ),
            email : FtRapi.encodeStr( this.email ),
            cell_no : FtRapi.encodeStr( this.cell_no ),
            phone : FtRapi.encodeStr( this.phone ),
            fax : FtRapi.encodeStr( this.fax ),
            assembly_id : this.assembly_id,
            province_id : this.province_id,
            region_id : this.region_id,
            attendee_type_id : this.attendee_type_id,
            contact_method_id : this.contact_method_id,
            capture_dttm : FtRapi.encodeDttm( this.capture_dttm ),
            payment_date : FtRapi.encodeDate( this.payment_date ),
            payment_online : FtRapi.encodeBool( this.payment_online ),
            payment : FtRapi.encodeBool( this.payment ),
            payment_amount : FtRapi.encodeDec( this.payment_amount ),
            payment_method_id : this.payment_method_id,
            card_printed : FtRapi.encodeBool( this.card_printed ),
            is_committee : FtRapi.encodeBool( this.is_committee ),
            reg_status_id : this.reg_status_id,
            goodybag_received : FtRapi.encodeBool( this.goodybag_received ),
            goodybag_scan_user_id : this.goodybag_scan_user_id,
            goodybag_scan_dttm : FtRapi.encodeDttm( this.goodybag_scan_dttm ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.conference_id = Ft.parseInt0Null( src.conference_id );
        this.surname = src.surname;
        this.fullnames = src.fullnames;
        this.title_id = Ft.parseInt0Null( src.title_id );
        this.rsaid_no = src.rsaid_no;
        this.postal_adr_1 = src.postal_adr_1;
        this.postal_adr_2 = src.postal_adr_2;
        this.postal_adr_code = src.postal_adr_code;
        this.email = src.email;
        this.cell_no = src.cell_no;
        this.phone = src.phone;
        this.fax = src.fax;
        this.assembly_id = Ft.parseInt0Null( src.assembly_id );
        this.province_id = Ft.parseInt0Null( src.province_id );
        this.region_id = Ft.parseInt0Null( src.region_id );
        this.attendee_type_id = Ft.parseInt0Null( src.attendee_type_id );
        this.contact_method_id = Ft.parseInt0Null( src.contact_method_id );
        this.capture_dttm = src.capture_dttm;
        this.payment_date = src.payment_date;
        this.payment_online = Ft.parseBool( src.payment_online );
        this.payment = Ft.parseBool( src.payment );
        this.payment_amount = new Decimal( src.payment_amount );
        this.payment_method_id = Ft.parseInt0Null( src.payment_method_id );
        this.card_printed = Ft.parseBool( src.card_printed );
        this.is_committee = Ft.parseBool( src.is_committee );
        this.reg_status_id = Ft.parseInt0Null( src.reg_status_id );
        this.goodybag_received = Ft.parseBool( src.goodybag_received );
        this.goodybag_scan_user_id = Ft.parseInt0Null( src.goodybag_scan_user_id );
        this.goodybag_scan_dttm = src.goodybag_scan_dttm;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.conference_id = null;
        this.surname = null;
        this.fullnames = null;
        this.title_id = null;
        this.rsaid_no = null;
        this.postal_adr_1 = null;
        this.postal_adr_2 = null;
        this.postal_adr_code = null;
        this.email = null;
        this.cell_no = null;
        this.phone = null;
        this.fax = null;
        this.assembly_id = null;
        this.province_id = null;
        this.region_id = null;
        this.attendee_type_id = null;
        this.contact_method_id = null;
        this.capture_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.payment_date = moment( '0001-01-01' ).toDate();
        this.payment_online = false;
        this.payment = false;
        this.payment_amount = null;
        this.payment_method_id = null;
        this.card_printed = false;
        this.is_committee = false;
        this.reg_status_id = null;
        this.goodybag_received = false;
        this.goodybag_scan_user_id = null;
        this.goodybag_scan_dttm = moment( '0001-01-01 00:00:00' ).toDate();
    }
}
