import AuditActionBase from './base/auditActionBase';

//********************************************************************
// AuditAction class
// @FTGEN_PROGRESS: busy
export default class AuditAction extends AuditActionBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static AA_ASSEMBLY_REGION_NEW = 1;
    static AA_ASSEMBLY_REGION_UPDATE = 2;
    static AA_ASSEMBLY_REGION_DELETE = 3;
    static AA_PASTOR_STATUSES_NEW = 4;
    static AA_PASTOR_STATUSES_UPDATE = 5;
    static AA_PASTOR_STATUSES_DELETE = 6;
    static AA_PASTOR_TITLES_NEW = 7;
    static AA_PASTOR_TITLES_UPDATE = 8;
    static AA_PASTOR_TITLES_DELETE = 9;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
